import dynamic from "next/dynamic"
import Link from "next/link"
import { useTranslation } from "next-i18next"
import Typography from "src/views/components/typography"

// ** Layouts
const MainLayout = dynamic(() => import("src/views/layouts/MainLayout"), { ssr: false })

//** Meta
import PageMeta from "src/views/pages/main/PageMeta"

// ** Page Meta
const pageMeta = {
  title: "MyJobs.ge",
  desc: "",
  img: ""
}

const Index = () => {
  const { t } = useTranslation("common")
  return (
    <>
      <PageMeta meta={pageMeta} />
      <MainLayout>
        <div className="my-32 text-center">
          <Typography type="h1">{t("PAGE_NOT_FOUND")}</Typography>
          <Link
            href="/"
            className="mt-12 inline-block rounded-xl border border-primary-100 p-4 text-primary-100 transition duration-300 ease-in hover:bg-primary-100 hover:text-white">
            {t("RETURN_ON_MAIN")}
          </Link>
        </div>
      </MainLayout>
    </>
  )
}

export default Index
