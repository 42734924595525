// ** Tailwind Styled
import tw from "tailwind-styled-components"

// ** Typography Component that implements the types in Figma spec.
// Different type variants can be set using props (Heading 1, 2, 3... = h1, h2, h3...)

// ₾

/*
10px - mini - text-xs
12px - small - text-sm
14px - text - text-2sm
16px - h5 - text-md
18px - h4 - text-2md
24px - H3 - text-2lg
*/
export type TypographyProps = (
  | {
      type: "h1" | "h2" | "h3" | "h4" | "h15"
      weight?: any // What is never???
      color?: any // What is never???
    }
  | {
      type: "button"
      weight: "bold" | "medium"
      color?: "dark" | "light"
    }
  | {
      type: "h5"
      weight: "bold" | "medium"
      color?: "dark" | "light" // What is never???
    }
  | {
      type: "body"
      color?: "dark" | "light"
      weight?: "medium" // What is never???
    }
  | {
      type: "text"
      color?: "dark" | "light"
      weight?: "medium"
    }
  | {
      type: "small" | "mini"
      color?: "dark" | "light"
      weight?: "regular" | "medium"
    }
) & {
  children?: any
  className?: string
  id?: string
  onClick?: () => void
  style?: any
  title?: string
}

const Typography = ({ type, weight, color, children, className, onClick, id, title, style }: TypographyProps) => {
  switch (type) {
    case "h1":
      return (
        <H1Typography className={className} id={id} onClick={onClick}>
          {children}
        </H1Typography>
      )
    case "h15":
      return (
        <H15Typography className={className} id={id} onClick={onClick}>
          {children}
        </H15Typography>
      )
    case "h2":
      return (
        <H2Typography className={className} id={id} onClick={onClick}>
          {children}
        </H2Typography>
      )
    case "h3":
      return (
        <H3Typography className={className} id={id} onClick={onClick}>
          {children}
        </H3Typography>
      )
    case "h4":
      return (
        <H4Typography weight={weight} color={color} className={className} id={id} onClick={onClick}>
          {children}
        </H4Typography>
      )
    case "h5":
      return (
        <H5Typography weight={weight} className={className} id={id} onClick={onClick}>
          {children}
        </H5Typography>
      )
    case "text":
      return (
        <TextTypography className={className} weight={weight} color={color} id={id} onClick={onClick} title={title}>
          {children}
        </TextTypography>
      )
    case "small":
      return (
        <SmallTypography className={className} color={color} weight={weight} id={id} onClick={onClick}>
          {children}
        </SmallTypography>
      )
    case "mini":
      return (
        <MiniTypography className={className} color={color} weight={weight} id={id} onClick={onClick}>
          {children}
        </MiniTypography>
      )
    case "body":
      return (
        <BodyTypography color={color} className={className} id={id} onClick={onClick}>
          {children}
        </BodyTypography>
      )
    case "button":
      return (
        <ButtonTypography weight={weight} color={color} className={className} id={id} onClick={onClick}>
          {children}
        </ButtonTypography>
      )
  }
}

interface TextComponent extends React.HTMLAttributes<HTMLElement> {
  children: any
  weight?: "bold" | "medium" | "regular"
  color?: "dark" | "light"
  className?: string
  title?: string
}

const H1Typography = tw.h1<TextComponent>`font-tbcx-bold text-2xl text-secondary-100 ${(props: any) =>
  props.className ? props.className : ""}`

const H15Typography = tw.h1<TextComponent>`font-tbcx-bold text-xl text-secondary-100 ${(props: any) =>
  props.className ? props.className : ""}`

const H2Typography = tw.h2<TextComponent>`font-tbcx-bold text-lg md:text-3lg text-secondary-100 ${(props: any) =>
  props.className ? props.className : ""}`

const H3Typography = tw.h3<TextComponent>`font-tbcx-bold text-2lg text-secondary-100 ${(props: any) =>
  props.className ? props.className : ""}`

const H4Typography = tw.h4<TextComponent>`
${(props: any) => (props.weight === "medium" ? "font-tbcx-medium" : "font-tbcx-bold")}
${(props: any) => (props.color === "light" ? "text-white" : "text-secondary-100")}
text-2md
${(props: any) => (props.className ? props.className : "")}
`
const H5Typography = tw.h5<TextComponent>`
${(props: any) => (props.weight === "medium" ? "font-tbcx-medium" : "font-tbcx-bold")}
text-secondary-100
text-md
${(props: any) => (props.className ? props.className : "")}
`
// TEXT TEXT TEXT TEXT
const TextTypography = tw.p<TextComponent>`
${(props: any) => (props.color === "light" ? "text-secondary-70" : "text-secondary-100")}
${(props: any) => (props.weight === "medium" ? "font-tbcx-medium" : "font-tbcx-regular")}
text-2sm
${(props: any) => (props.className ? props.className : "")}`

const SmallTypography = tw.span<TextComponent>`
${(props: any) => (props.weight === "medium" ? "font-tbcx-medium" : "font-tbcx-regular")}
${(props: any) => (props.color === "light" ? "text-secondary-70" : "text-secondary-100")}
text-sm
${(props: any) => (props.className ? props.className : "")}
`

const BodyTypography = tw.p<TextComponent>`font-tbcx-regular text-2sm
${(props: any) => (props.color === "light" ? "text-secondary-70" : "text-secondary-100")}
${(props: any) => (props.weight === "medium" ? "font-tbcx-medium" : "font-tbcx-regular")}
${(props: any) => (props.className ? props.className : "")}
`

const ButtonTypography = tw.span<TextComponent>`
${(props: any) => (props.weight === "medium" ? "font-tbcx-medium" : "font-tbcx-bold")}
${(props: any) => (props.color === "light" ? "text-white" : "text-secondary-100")}
text-2sm
${(props: any) => (props.className ? props.className : "")}
`

const MiniTypography = tw.p<TextComponent>`
${(props: any) => (props.weight === "medium" ? "font-tbcx-medium" : "font-tbcx-regular")}
${(props: any) => (props.color === "light" ? "text-secondary-70" : "text-secondary-100")}
text-xs
${(props: any) => (props.className ? props.className : "")}`

export default Typography
